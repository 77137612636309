import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../../assets/images/logo/header-logo.png";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import shape4 from "../../../../assets/images/shapes/shape-04.png";

const FooterOne = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <footer id="contact" className="main__footer">
        <div className="accomplised__section">
          <div className="auto-container">
            <div className="accomplised__data p_relative">
              <div className="pattern-layer">
                <div
                  className="pattern-4 p_absolute"
                  data-parallax='{"x": 100}'
                  style={{ backgroundImage: `url(${shape4})` }}
                ></div>
              </div>
              <div className="row">
                <div
                  className="col-lg-7 col-md-12"
                  data-aos="fade-left"
                  data-aos-delay="650"
                  data-aos-duration="3000"
                >
                  <div className="title__data">
                    <div className="sub__title">
                      <h4>Hubungi Kami</h4>
                    </div>
                    <div className="title">
                      <h2>Anda dapat menghubungi kami kapan saja.</h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-4 col-md-12">
                  <div className="funfact__data">
                    <div
                      className="funfact__content one"
                      data-aos="zoom-in"
                      data-aos-delay="1000"
                      data-aos-duration="3000"
                    >
                      <Link
                        to="https://www.instagram.com/surosowancyber/"
                        target="_blank"
                      >
                        <FaInstagramSquare style={{ fontSize: "50px" }} />
                      </Link>
                    </div>
                    <div
                      className="funfact__content two "
                      data-aos="zoom-in"
                      data-aos-delay="1200"
                      data-aos-duration="3000"
                    >
                      <Link
                        to="https://twitter.com/SurosowanCyber?s=20&t=8MhhWixS-56llzXkbKocdw"
                        target="_blank"
                      >
                        <FaSquareXTwitter style={{ fontSize: "50px" }} />
                      </Link>
                    </div>
                    <div
                      className="funfact__content three"
                      data-aos="zoom-in"
                      data-aos-delay="1400"
                      data-aos-duration="3000"
                    >
                      <Link
                        to="https://www.linkedin.com/company/surosowan-cyber/"
                        target="_blank"
                      >
                        <FaLinkedin style={{ fontSize: "50px" }} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-top">
          <div className="auto-container">
            <div
              className="top-inner"
              data-aos="fade-left"
              data-aos-delay="600"
              data-aos-duration="3000"
            >
              <div className="left__top">
                <h3 style={{ fontSize: "21px" }}>
                  Jika anda ingin berdiskusi dan atau mempunyai sebuah
                  pertanyaan terkait Komunitas Kami.<br></br>kami siap
                  memberikan jawaban
                </h3>
              </div>
              <div className="right__top">
                <div className="btn-box">
                  <Link
                    target="_blank"
                    to="mailto:contact@surosowancyber.org"
                    className="theme-btn theme-btn-one"
                  >
                    <i className="icon-02"></i> Hubungi Kami
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__one">
          <div className="footer-widget-section">
            <div className="auto-container">
              <div
                className="row clearfix"
                style={{ justifyContent: "space-between" }}
              >
                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                  <div
                    className="footer-widget footer-logo-widget wow fadeInUp animated"
                    data-wow-delay="00ms"
                    data-wow-duration="1500ms"
                  >
                    <figure className="footer-logo">
                      <Link to="/">
                        <img src={Logo} alt="" />
                      </Link>
                    </figure>
                    <div className="text">
                      <p>
                        Kita mengerti bahwa lebih baik bergerak dan membangun
                        sesuatu. Kita akan terus berkarya untuk kebaikan Nusa
                        dan Bangsa.
                      </p>
                    </div>
                    <div className="btn-box">
                      <Link to="/history" className="theme-btn theme-btn-one">
                        <i className="icon-02"></i> Tentang Kami
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                  <div
                    className="footer-widget news-letter-widget ml_80 wow fadeInUp animated"
                    data-wow-delay="200ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="widget-title">
                      <h4>Newsletter</h4>
                    </div>
                    <div className="text">
                      <p>
                        Apa yang sedang tren dan mendominasi dunia IT saat ini?
                        Temukan jawabannya di dalam newsletter kami.
                      </p>
                    </div>
                    <div className="subscribe-inner">
                      <form
                        action="contact"
                        method="post"
                        className="subscribe-form"
                        onSubmit={handleSubmit}
                      >
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Your mail address *"
                            required=""
                          />
                          <div className="btn-box">
                            <button
                              className="theme-btn theme-btn-one"
                              type="submit"
                            >
                              <i className="icon-02"></i> GO
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                  <div
                    className="footer-widget office-widget wow fadeInUp animated"
                    data-wow-delay="400ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="widget-title">
                      <h4>Official info:</h4>
                    </div>
                    <div className="widget-content">
                      <div className="text">
                        <p>Serang - Banten</p>
                      </div>
                      <Link target="_blank" to="tel:+62 851‑7969‑6022">
                        +62 851‑7969‑6022
                      </Link>{" "}
                      <br />
                      <Link
                        target="_blank"
                        to="mailto: contact@surosowancyber.or.id"
                      >
                        contact@surosowancyber.or.id
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-6 col-sm-12 footer-column pr-0">
                  <div
                    className="footer-widget gallery-widget wow fadeInUp animated"
                    data-wow-delay="600ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="widget-title">
                      <h4>Gallery</h4>
                    </div>
                    <div className="widget_content">
                      <ul className="instagram_list clearfix">
                        <li>
                          <div className="inner__box">
                            <div className="image__box">
                              <Link to="/projects-details">
                                <img src={g2} alt="" />
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="inner__box">
                            <div className="image__box">
                              <Link to="/projects-details">
                                <img src={g3} alt="" />
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="inner__box">
                            <div className="image__box">
                              <Link to="/projects-details">
                                <img src={g4} alt="" />
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="inner__box">
                            <div className="image__box">
                              <Link to="/projects-details">
                                <img src={g1} alt="" />
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="inner__box">
                            <div className="image__box">
                              <Link to="/projects-details">
                                <img src={g5} alt="" />
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="inner__box">
                            <div className="image__box">
                              <Link to="/projects-details">
                                <img src={g6} alt="" />
                              </Link>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="auto-container">
              <div className="bottom-inner">
                <div className="copyright">
                  <p>
                    © 2024 <Link to="/">Surosowan Cyber.</Link> All rights
                    reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;

import React from "react";
import Header from "../components/layouts/Header/Header";
import Footer from "../components/layouts/Footer/Footer";
import Portfolio from "../components/Home/Portfolio/Portfolio";
import { Helmet } from "react-helmet";

const PortfolioPage = () => {
  return (
    <>
      <Helmet>
        <title>Portfolio - Surosowan Siber</title>
        <meta
          name="keywords"
          content="Information Technology Foundation, Community, Network, Web Development, Full-Stack Developer"
        />
        <meta name="author" content="Surosowan Siber Foundation" />
        <meta image="https://surosowancyber.or.id/images/logo/header-logo.png" />

        <meta property="og:url" content="https://surosowancyber.or.id" />
        <meta property="og:type" content="foundation" />
        <meta property="og:title" content="Surosowan Siber Foundation" />
        <meta
          property="og:description"
          content="Dalam menjalankan semua kegiatan, Surosowan Siber mencoba untuk menanamkan nilai utama IPEACE: [I]nnovative, [P]assionate, [E]ngage,[A]daptive, [C]ollaborative dan [E]fficient. Bertujuan untuk tetap menjaga semangat dalam keterlibatan seluruh elemen yang ada di Surosowan Siber dalam menjalankan program"
        />
        <meta property="og:site_name" content="Surosowan Siber Foundation" />
        <meta
          property="og:image"
          content="https://surosowancyber.or.id/static/media/banner-logo.ba4985e73aff29852c51.png"
        />
        <link rel="canonical" href="https://surosowancyber.or.id/portfolio" />
      </Helmet>
      <Header></Header>
      <Portfolio></Portfolio>
      <Footer></Footer>
    </>
  );
};

export default PortfolioPage;

import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import HeaderLogo from "../../../../assets/images/logo/header-logo.png";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

export default function Header({ variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);

  return (
    <>
      <header
        className={`main-header header__style__one ${
          variant ? variant : ""
        } header__sticky ${isSticky ? "fixed-header" : ""}`}
      >
        <div className="header-top-one">
          <div className="auto-container">
            <div className="top__inner">
              <div className="top__hrader__left">
                <ul>
                  <li>
                    <Link target="_blank" to="tel:+62 851‑7969‑6022">
                      <span>Telpon :</span> +62 851‑7969‑6022
                    </Link>
                  </li>
                  <li>/</li>
                  <li>
                    <span>Email :</span>{" "}
                    <Link
                      target="_blank"
                      to="mailto:contact@surosowancyber.or.id"
                    >
                      contact@surosowancyber.or.id
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="top__hrader__right">
                <ul>
                  <li>
                    <Link
                      target="_blank"
                      to="https://twitter.com/SurosowanCyber?s=20&t=8MhhWixS-56llzXkbKocdw"
                    >
                      <FaSquareXTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      to="https://www.linkedin.com/company/surosowan-cyber/"
                    >
                      <FaLinkedin />
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      to="https://www.instagram.com/surosowancyber/"
                    >
                      <FaInstagramSquare />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="header-lower">
          <div className="auto-container">
            <div className="outer-box">
              <div className="logo-box">
                <figure className="logo">
                  <Link to="/home">
                    <img
                      src={HeaderLogo}
                      style={{ width: "230px" }}
                      alt="Surosowan Cyber"
                    />
                  </Link>
                </figure>
              </div>
              <div className="menu-area clearfix">
                <div
                  className={
                    mobileToggle ? "mobile-menu-visible" : "mobile-menus"
                  }
                >
                  <div
                    className="mobile-nav-toggler"
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <i className="icon-bar"></i>
                    <i className="icon-bar"></i>
                    <i className="icon-bar"></i>
                  </div>
                  <div className="mobile-menu">
                    <div className="menu-backdrop"></div>
                    <div
                      className="close-btn"
                      onClick={() => setMobileToggle(!mobileToggle)}
                    >
                      <i className="icon-fa-times"></i>
                    </div>

                    <nav className="menu-box">
                      <div className="nav-logo">
                        <Link to="/home">
                          <img
                            src={HeaderLogo}
                            alt="Surosowan Cyber"
                            title="Surosowan Cyber"
                          />
                        </Link>
                      </div>
                      <div className="menu-outer">
                        <div
                          className="collapse navbar-collapse show clearfix"
                          id="navbarSupportedContent"
                        >
                          <ul className="navigation clearfix home-menu">
                            <li>
                              <NavLink to="/home">Beranda</NavLink>
                            </li>
                            <li>
                              <NavLink to="/history">Sejarah</NavLink>
                            </li>
                            <li>
                              <NavLink to="/portfolio">Portfolio</NavLink>
                            </li>
                            <li>
                              <NavLink to="/team">Tim</NavLink>
                            </li>
                            <li>
                              <NavLink to="/registration">Pendaftaran</NavLink>
                            </li>
                            <li>
                              <NavLink to="/contact">Kontak</NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="contact-info">
                        <h4>Contact Info</h4>
                        <ul>
                          <li>Serang - Banten</li>
                          <li>
                            <Link target="_blank" to="tel:+62 851‑7969‑6022">
                              +62 851‑7969‑6022
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="mailto:contact@surosowancyber.or.id"
                            >
                              contact@surosowancyber.or.id
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                <nav className="main-menu navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix home-menu">
                      <li>
                        <NavLink to="/home">Beranda</NavLink>
                      </li>
                      <li>
                        <NavLink to="/history">Sejarah</NavLink>
                      </li>
                      <li>
                        <NavLink to="/portfolio">Portfolio</NavLink>
                      </li>
                      <li>
                        <NavLink to="/team">Tim</NavLink>
                      </li>
                      <li>
                        <NavLink to="/registration">Pendaftaran</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact">Kontak</NavLink>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="btn-box">
                <Link
                  target="_blank"
                  to="https://t.me/surosowancyber"
                  className="theme-btn theme-btn-one"
                >
                  <i className="icon-02"></i> Forum
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-header">
          <div className="auto-container">
            <div className="outer-box">
              <div className="logo-box">
                <figure className="logo">
                  <Link to="/home">
                    <img
                      src={HeaderLogo}
                      style={{ width: "230px" }}
                      alt="Surosowan Cyber"
                    />
                  </Link>
                </figure>
              </div>
              <div className="menu-area clearfix">
                <nav className="main-menu clearfix">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix home-menu">
                      <li>
                        <NavLink to="/home">Beranda</NavLink>
                      </li>
                      <li>
                        <NavLink to="/history">Sejarah</NavLink>
                      </li>
                      <li>
                        <NavLink to="/portfolio">Portfolio</NavLink>
                      </li>
                      <li>
                        <NavLink to="/team">Tim</NavLink>
                      </li>
                      <li>
                        <NavLink to="/registration">Pendaftaran</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact">Kontak</NavLink>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="btn-box">
                <Link
                  target="_blank"
                  to="https://forum.surosowancyber.org/?"
                  className="theme-btn theme-btn-one"
                >
                  <i className="icon-02"></i> Forum
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

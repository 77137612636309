import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

//** import pages */
import Home from "./Pages/Home/Home";
import History from "./Pages/History/History";
import Team from "./Pages/CreativeTeam/CreativeTeam";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Registration from "./Pages/Registration/Registration";
import Contact from "./Pages/Contact/Contact";

// ** import component
import ScrollTop from "./Pages/components/ScrollTop/ScrollTop";

// ** not found 404
import Error from "./Pages/Error/Error";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/history" element={<History />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/team" element={<Team />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/contact" element={<Contact />} />

          {/* page not found */}
          <Route path="/*" element={<Error />} />
        </Routes>
      </Router>
      <ScrollTop />
    </>
  );
}

export default App;

import React, { useEffect } from "react";
// core version + navigation, pagination modules:
import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Link } from "react-router-dom";
import "swiper/css/pagination";
import logo from "../../../../assets/images/logo/banner-logo.png";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

// ** import creative team data
import creativeTeamData from "../../../../data/CreativeTeam";

const CreativeTeam = () => {
  useEffect(() => {
    const swiper = new Swiper(".swiper-container", {
      modules: [Navigation, Autoplay],

      // Optional parameters
      direction: "horizontal",
      loop: true,

      // Navigation arrows
      navigation: {
        nextEl: ".button_prev",
        prevEl: ".button_next",
      },

      // And if we need scrollbar
      scrollbar: {
        el: ".swiper-scrollbar",
      },

      // Autoplay settings
      autoplay: {
        delay: 1500,
        disableOnInteraction: false,
      },

      breakpoints: {
        1800: {
          slidesPerView: 4,
        },
        1600: {
          slidesPerView: 4,
        },
        1400: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 3,
        },
        800: {
          slidesPerView: 3,
        },
        767: {
          slidesPerView: 2,
        },
        320: {
          slidesPerView: 1,
        },
      },
    });

    return () => {
      swiper.destroy();
    };
  }, []);


  return (
    <section className="testimonial__section p_relative">
      <div className="auto-container">
        <div className="testimonial__top">
          <div
            className="title__data"
            data-aos="fade-right"
            data-aos-delay="5000"
            data-aos-duration="3000"
          >
            <div className="sub__title">
              <h4>Creative Team</h4>
            </div>
            <div className="title">
              <h2>We Have Expert Member</h2>
            </div>
          </div>
          <div
            className="swiper__button"
            data-aos="fade-right"
            data-aos-delay="5000"
            data-aos-duration="3000"
          >
            <div className="button_next">
              <i className="icon-06"></i>
            </div>
            <div className="button_prev">
              <i className="icon-05"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-container">
        <div
          className="swiper-wrapper"
          data-aos="zoom-in"
          data-aos-delay="1000"
          data-aos-duration="3000"
        >
          {creativeTeamData.map((item, index) => (
            <div key={index} className="swiper-slide testimonials__block__one">
              <div className="test__bg"></div>
              <div className="testimonials__info">
                <div className="authore__img">
                  <figure className="image">
                    <img src={item.image} alt={item.name} />
                  </figure>
                </div>
                <div className="team__media">
                  <ul>
                    <li>
                      <Link target="_blank" to={item.social_media[0].linkedin}>
                        <FaLinkedin style={{ fontSize: "25px" }} />
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to={item.social_media[0].twitter}>
                        <FaSquareXTwitter style={{ fontSize: "25px" }} />
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to={item.social_media[0].instagram}>
                        <FaInstagramSquare style={{ fontSize: "25px" }} />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="authore__info">
                  <h5>{item.name}</h5>
                  <p>{item.position}</p>
                </div>
              </div>
              <div className="logo">
                <img src={logo} alt={"test"} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CreativeTeam;

import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

// slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// ** import activities data
// import activityData from "../../../../data/Activity";

const Portfolio = () => {
  const sliderPortfolioSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api-surosowancyber.vercel.app/activities/"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <section id="portfolio" className="blog__section see__pad p_relative">
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div
              className="title__data"
              data-aos="fade-right"
              data-aos-delay="650"
              data-aos-duration="2000"
            >
              <div className="sub__title">
                <h4>Kegiatan Komunitas</h4>
              </div>
              <div className="title">
                <h2>Our Activities</h2>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12"
            data-aos="fade-right"
            data-aos-delay="950"
            data-aos-duration="3000"
          >
            <div className="normaol__text">
              <p>
                Dalam menjalankan semua kegiatan, Surosowan Siber mencoba untuk
                menanamkan nilai utama <span>IPEACE</span>: <span>[I]</span>
                nnovative, <span>[P]</span>assionate, <span>[E]</span>ngage,
                <span>[A]</span>daptive, <span>[C]</span>ollaborative dan{" "}
                <span>[E]</span>fficient. Bertujuan untuk tetap menjaga semangat
                dalam keterlibatan seluruh elemen yang ada di Surosowan Siber
                dalam menjalankan program
              </p>
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{ marginTop: "30px" }}
          data-aos="fade-up"
          data-aos-delay="950"
          data-aos-duration="3000"
        >
          <Slider {...sliderPortfolioSettings}>
            {loading ? (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  minHeight: "100vh",
                }}
              >
                Loading...
              </div>
            ) : (
              data.data?.map((item, id) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-12 news__block"
                  key={id}
                >
                  <div
                    className="news___block wow slideInUp animated animated"
                    data-wow-delay={item.data_aos_delay}
                    data-wow-duration="1500ms"
                  >
                    <div className="inner-box p_relative">
                      <div className="image-box p_relative d_block">
                        <figure className="image p_relative d_block">
                          <Link to="#">
                            <img src={item.imageUrl} alt={item.title} />
                          </Link>
                        </figure>
                        <div className="post__date">
                          <ul>
                            <li>
                              <i className="icon-15"></i>
                              {item.year}
                            </li>
                            {/* <li className="two"></li> */}
                            {/* <li>
                  <i className="icon-09"></i> {item.author}
                </li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="lower___content p_relative">
                        <h4>
                          <Link to="#">{item.title}</Link>
                        </h4>
                        <p>{item.name}</p>
                        <div className="btn__box__two">
                          <Link to={item.href} className="theme__btn__two">
                            <i className="icon-02"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;

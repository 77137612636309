//** import team images

import Pisang from "../assets/images/creative-team/pisang.png";

export const creativeTeam = [
  {
    id: 1,
    name: "Rifki Nugraha",
    position: "Ketua Umum",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "https://www.linkedin.com/in/rifki-nugraha/",
        instagram: "https://www.instagram.com/rifkiway/",
        twitter: "https://twitter.com/Rifkiway",
      },
    ],
  },
  {
    id: 2,
    name: "Fadhi Rekayasa",
    position: "Sekrestaris Umum",
    image: Pisang,
    data_aos_delay: "10ms",
    social_media: [
      {
        linkedin: "https://www.linkedin.com/in/fadhi-rekayasa-bab14a7b/",
        instagram: "https://www.instagram.com/fadhireka/",
        twitter: "https://twitter.com/fadhireka",
      },
    ],
  },
  {
    id: 4,
    name: "Rani Nadhiroh",
    position: "Wakil Sekrestaris Umum",
    image: Pisang,
    data_aos_delay: "30ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "https://www.instagram.com/rraaninad/",
        twitter: "#",
      },
    ],
  },
  {
    id: 5,
    name: "Bambang P. Asmoro",
    position: "Bendahara Umum",
    image: Pisang,
    data_aos_delay: "40ms",
    social_media: [
      {
        linkedin: "https://www.linkedin.com/in/bambang-purbo-488ab4178/",
        instagram: "https://www.instagram.com/bajahitam000/",
        twitter: "#",
      },
    ],
  },
  {
    id: 6,
    name: "Nina Luviana",
    position: "Wakil Bendahara Umum",
    image: Pisang,
    data_aos_delay: "50ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "#",
        twitter: "#",
      },
    ],
  },

  {
    id: 8,
    name: "Iman Winaldi",
    position: "Koordinator Akademi",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "#",
        twitter: "#",
      },
    ],
  },
  {
    id: 9,
    name: "Dede Anda",
    position: "Koordinator SDA",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "https://www.linkedin.com/in/dede-anda/",
        instagram: "https://www.instagram.com/dede_irgiie/",
        twitter: "#",
      },
    ],
  },
  {
    id: 10,
    name: "Muhammad Risyad F",
    position: "Koordinator Humas",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin:
          "https://www.linkedin.com/in/muhammad-risyad-fahlevi-19ab441b1/",
        instagram: "https://www.instagram.com/risyadfahlevi/",
        twitter: "https://twitter.com/RisyadFahlevi",
      },
    ],
  },
  {
    id: 11,
    name: "Arvin Lazuardi",
    position: "Koordinator Asset",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "https://www.linkedin.com/in/muhammadarvinl/",
        instagram: "https://www.instagram.com/arvin.lazuardi/",
        twitter: "#",
      },
    ],
  },
  {
    id: 12,
    name: "Bramantyo Ardi",
    position: "Sub Bagian Web",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "https://www.linkedin.com/in/bramantyo-ardi/",
        instagram: "https://www.instagram.com/jakuja_/",
        twitter: "https://twitter.com/BramantyoArdi",
      },
    ],
  },
  {
    id: 13,
    name: "Rudi Hikmatullah",
    position: "Sub Bagian Web",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "https://instagram.com/rxxdee/",
        twitter: "#",
      },
    ],
  },
  {
    id: 14,
    name: "Median Prasetya",
    position: "Sub Bagian Web",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "https://instagram.com/medianprasetya",
        twitter: "#",
      },
    ],
  },
  {
    id: 15,
    name: "Syarif Hidayatullah",
    position: "Sub Bagian Web",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "#",
        twitter: "#",
      },
    ],
  },
  {
    id: 16,
    name: "Rafi Arham",
    position: "Sub Bagian Network",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "https://www.linkedin.com/in/rafi-arham-b45167199/",
        instagram: "https://www.instagram.com/rafiarhaam/",
        twitter: "https://twitter.com/rafiarhaam",
      },
    ],
  },
  {
    id: 17,
    name: "Julian Baja Gunawan",
    position: "Sub Bagian SysAdmin",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "https://www.linkedin.com/in/julian-b-106439226/",
        instagram: "https://www.instagram.com/julianbgunawan/",
        twitter: "#",
      },
    ],
  },
  {
    id: 18,
    name: "Ibnu Fachrizal",
    position: "Sub Bagian Cyber Security",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "https://www.linkedin.com/in/ibnufachrizal",
        instagram: "https://instagram.com/ibnufachrizal",
        twitter: "https://www.twitter.com/ibnufachrizal",
      },
    ],
  },
  {
    id: 19,
    name: "Rama Aryo Pambudi",
    position: "Sub Bagian Cyber Security",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "#",
        twitter: "#",
      },
    ],
  },
  {
    id: 20,
    name: "Tezar Alpha Yohansa",
    position: "Sub Bagian SDA",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "#",
        twitter: "#",
      },
    ],
  },
  {
    id: 21,
    name: "Eliza Putri",
    position: "Sub Bagian SDA",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "#",
        twitter: "#",
      },
    ],
  },
  {
    id: 22,
    name: "Dwi Rachmat Prakoso",
    position: "Sub Bagian Humas",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "https://www.linkedin.com/in/dwi-rachmat-prakoso-1bb73796/",
        instagram: "https://www.instagram.com/dwirprakoso/",
        twitter: "#",
      },
    ],
  },
  {
    id: 23,
    name: "Lingga Ayubi Fahlufi",
    position: "Sub Bagian Humas",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin:
          "https://www.linkedin.com/in/lingga-ayyubi-fahlufi-6524b5228/",
        instagram: "https://www.instagram.com/lingga.ayyubi/",
        twitter: "https://twitter.com/Lingga_Ayyubi",
      },
    ],
  },
  {
    id: 24,
    name: "Heri Firmansyah",
    position: "Sub Bagian Humas",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "#",
        twitter: "#",
      },
    ],
  },
  {
    id: 25,
    name: "Mahendra Muhammad",
    position: "Sub Bagian Humas",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "https://www.linkedin.com/in/mahendra-muhammad-42b208120/",
        instagram: "https://www.instagram.com/hendraajie/",
        twitter: "https://twitter.com/hendraajie",
      },
    ],
  },
  {
    id: 26,
    name: "Adinda Rizky",
    position: "Sub Bagian Humas",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "#",
        twitter: "#",
      },
    ],
  },
  {
    id: 27,
    name: "Arif Rizqi",
    position: "Sub Web Dev",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "#",
        twitter: "#",
      },
    ],
  },
  {
    id: 28,
    name: "Ahmad Fatoni",
    position: "Sub Bagian Humas",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "#",
        twitter: "#",
      },
    ],
  },
  {
    id: 29,
    name: "Panji Ramadhan",
    position: "Sub Bagian Asset",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "#",
        twitter: "#",
      },
    ],
  },
  {
    id: 29,
    name: "Angga Pratama",
    position: "Sub Bagian Mobil Dev",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "#",
        instagram: "#",
        twitter: "#",
      },
    ],
  },
  {
    id: 29,
    name: "Khaidir Fahram",
    position: "Sekrestaris Umum",
    image: Pisang,
    data_aos_delay: "00ms",
    social_media: [
      {
        linkedin: "https://www.linkedin.com/in/fahram",
        instagram: "https://www.instagram.com/khaidir.fahram",
        twitter: "https://twitter.com/fahram",
      },
    ],
  },
];

export default creativeTeam;

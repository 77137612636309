import React from "react";
import { Link } from "react-router-dom";
// import { HiOutlineLockOpen } from "react-icons/hi";
// import registrationData from "../../../../data/Registration";

const Registration = () => {
  return (
    <section id="registration" className="service__section p_relative">
      <div className="auto-container">
        <div className="service__data">
          <div
            className="title__data"
            data-aos="fade-right"
            data-aos-delay="600"
            data-aos-duration="2000"
          >
            <div className="sub__title">
              <h4>Pendaftaran</h4>
            </div>
            <div className="title">
              <h2 style={{ fontSize: "25px" }}>
                Semua orang dapat bergabung dalam Workshop, Akademi dan Seminar.
              </h2>
            </div>
          </div>
          <div
            className="row"
            data-aos="zoom-in"
            data-aos-delay="800"
            data-aos-duration="2000"
          >
            <div className="footer-top">
              <div className="auto-container">
                <div
                  className="top-inner"
                  data-aos="fade-left"
                  data-aos-delay="600"
                  data-aos-duration="3000"
                >
                  <div className="left__top">
                    <h5 style={{ fontSize: "15px" }}>
                      Pendaftaran untuk Akademi Surosowan Cyber telah resmi
                      dibuka! Kami mengundang Anda yang berminat untuk
                      memperdalam pengetahuan dan keterampilan di berbagai aspek
                      Teknologi Informasi (TI). Akademi ini mencakup berbagai
                      bidang, tidak terbatas pada keamanan siber, pengembangan
                      perangkat lunak, jaringan komputer, analisis data, dan
                      teknologi cloud. Program kami dirancang untuk memberikan
                      pemahaman yang komprehensif serta keterampilan praktis
                      yang diperlukan dalam industri TI saat ini.
                    </h5>
                    <h5 style={{ fontSize: "15px", marginTop: "10px" }}>
                      Dapatkan kesempatan untuk belajar dari para ahli,
                      berpartisipasi dalam proyek-proyek nyata, dan membangun
                      jaringan profesional yang kuat. Jangan lewatkan kesempatan
                      emas ini! Daftarkan diri Anda sekarang dan jadilah bagian
                      dari komunitas TI yang inovatif dan dinamis di Provinsi
                      Banten. Untuk informasi lebih lanjut dan pendaftaran,
                      kunjungi situs web kami atau hubungi tim pendaftaran kami.
                    </h5>
                  </div>
                  <div className="right__top">
                    <div
                      className="btn-box"
                      style={{ marginTop: "15px", marginBottom: "15px" }}
                    >
                      <Link
                        to="https://academy.surosowancyber.or.id/auth/signup"
                        className="theme-btn theme-btn-one"
                      >
                        <i className="icon-02"></i> Daftar Sekarang
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {registrationData.map((item, id) => (
              <div
                className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated"
                data-wow-delay={item.data_aos_delay}
                data-wow-duration="1500ms"
                key={id}
              >
                <div className="service__block">
                  <div className="service__icon">
                    <i className={item.icon}></i>
                    <div className="service__icon__two"></div>
                  </div>
                  <div className="service__text">
                    <h4>
                      <Link to={item.href}>{item.title}</Link>
                    </h4>
                    <p>{item.description}</p>
                    <p
                      style={{
                        fontSize: "30px",
                        fontWeight: "bold",
                        textDecoration: "line-through",
                      }}
                    >
                      {item.status}
                    </p>
                  </div>
                  <div className="service__button">
                    <Link to={item.href}>
                      <HiOutlineLockOpen />
                    </Link>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Registration;

import React from "react";
// import CountUp from "react-countup";
import BannerBg from "../../../../assets/images/shapes/shape-02.png";
import AboutUs from "../../../../assets/images/profile/sc.png";
// import shape1 from "../../../../assets/images/shapes/shape-01.png";

const History = () => {
  return (
    <section className="about__section p_relative see__pad">
      <div className="pattern-layer">
        <div
          className="pattern-2 p_absolute"
          data-parallax='{"x": -50}'
          style={{ backgroundImage: `url(${BannerBg})` }}
        ></div>
      </div>
      {/* <div
        className="boild__text"
        data-aos="fade-right"
        data-aos-delay="350"
        data-aos-duration="1000"
      >
        <h1>Surosowan Cyber</h1>
      </div> */}
      <div className="auto-container" style={{ marginTop: "30px" }}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="about__block p_relative">
              <figure
                data-aos="zoom-in"
                data-aos-delay="450"
                data-aos-duration="2000"
              >
                <img
                  src={AboutUs}
                  alt="Surosowan Cyber"
                  style={{
                    boxShadow: "0 11px 85px 5px rgb(147, 61, 245, 1) ",
                  }}
                />
              </figure>
              {/* <div
                data-aos="zoom-in"
                data-aos-delay="650"
                data-aos-duration="2000"
                className="funfact__content about"
                style={{ backgroundImage: `url(${shape1})` }}
              >
                <div className="count-outer count-box">
                  <h1 className="count-text">
                    <CountUp start={0} end={36} />
                    <span>+</span>
                  </h1>
                </div>
                <p>Anggota Aktif</p>
              </div> */}
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-5 col-md-12">
            <div className="about__text__block">
              <div
                className="title__data"
                data-aos="zoom-in"
                data-aos-delay="750"
                data-aos-duration="2000"
              >
                <div className="sub__title">
                  <h4>Profile</h4>
                </div>
                <div className="title">
                  <h2>Surosowan Cyber </h2>
                </div>
              </div>
              <div
                className="texts"
                data-aos="fade-right"
                data-aos-delay="750"
                data-aos-duration="2000"
              >
                <p>
                  Komunitas yang dipimpin langsung oleh anak muda yang berfokus
                  untuk mendorong minat dan potensi pemuda melalui Teknologi
                  Informasi dan Komunikasi (TIK) di Provinsi Banten melalui
                  Roadshow, Academy dan Seminar.
                </p>
                <br></br>
                <p>
                  (1). Memberikan solusi terkait masalah serta tantangan yang
                  ada di Banten melalui pengetahuan dan atau melalui Teknologi
                  Informasi.
                </p>
                <br></br>
                <p>
                  (2). Menjadi wadah pendidikan non-profit yang diselenggaraan
                  langsung dari tenaga ahli dan pemuda yang ada di Banten.
                </p>
                <br></br>
                <p>
                  (3). Meningkatkan kualitas sumber daya manusia yang terampil
                  di bidang Teknologi Informasi dan berdampak untuk kemajuan
                  daerah.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default History;

import React from "react";
// import BannerLogo from "../../../../assets/images/logo/banner-logo.png";
// import Us from "../../../../assets/images/logo/header-logo.png";
// import choose3 from "../../../../assets/images/resource/choose-02.png";

const VisiMisi = () => {
  return (
    <section id="visi-misi" className="choose__us p_relative">
      <div className="choose__us__data">
        <div className="auto-container">
          <div className="row">
            {/* <div
              className="col-lg-6 col-md-12"
              data-aos="zoom-in-up"
              data-aos-delay="250"
              data-aos-duration="1000"
            >
              <div className="choose__block">
                <figure>
                  <img src={Us} alt="Surosowan Cyber" />
                </figure>
              </div>
            </div> */}
            <div
              className="title__data"
              data-aos="fade-right"
              data-aos-delay="250"
              data-aos-duration="1300"
            >
              <div className="sub__title">
                <h4>Visi&Misi</h4>
              </div>
              <div className="title">
                <h2>Surosowan Cyber </h2>
              </div>
            </div>
            <div className="visi__misi">
              <div className="visi">
                <div
                  data-aos="fade-right"
                  data-aos-delay="450"
                  data-aos-duration="2000"
                >
                  <h3>Visi :</h3>
                  <p>
                    Meningkatkan minat dan pengetahuan pelajar di bidang
                    Teknologi Informasi dan Komunikasi untuk melahirkan
                    generasi IT berkualitas yang memberikan dampak positif
                    untuk daerah
                    </p>
                </div>
              </div>
              <div className="misi">
                <div
                  data-aos="fade-right"
                  data-aos-delay="650"
                  data-aos-duration="2000"
                >
                  <h3>Misi:</h3>
                  <p>
                    (1). Melaksanakan pelatihan dan edukasi di bidang
                    Teknologi Informasi di Provinsi Banten yang berdampak
                    positif serta berkelanjutan untuk masyarakat.
                    </p>
                  <br></br>
                  <p>
                    (2). Melaksanakan kegiatan dan kerjasama yang berdampak
                    positif untuk masyarakat, komunitas, instansi pemerintahan
                    dan lembaga swasta.
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VisiMisi;

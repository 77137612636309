import React from "react";
import Header from "../components/layouts/Header/Header";
import Footer from "../components/layouts/Footer/Footer";
import Registration from "../components/Home/Registration/Registration";
import { Helmet } from "react-helmet";

const RegistrationPage = () => {
  return (
    <>
      <Helmet>
        <title>Pendaftaran - Surosowan Siber</title>
        <meta
          name="keywords"
          content="Information Technology Foundation, Community, Network, Web Development, Full-Stack Developer"
        />
        <meta name="author" content="Surosowan Siber Foundation" />
        <meta image="https://surosowancyber.or.id/images/logo/header-logo.png" />

        <meta
          property="og:url"
          content="https://surosowancyber.or.id/registration"
        />
        <meta property="og:type" content="foundation" />
        <meta property="og:title" content="Surosowan Siber Foundation" />
        <meta
          property="og:description"
          content="Surosowan Siber is a place for those who excited about Technology and a place for sharing and learning"
        />
        <meta property="og:site_name" content="Surosowan Siber Foundation" />
        <meta
          property="og:image"
          content="https://surosowancyber.or.id/static/media/banner-logo.ba4985e73aff29852c51.png"
        />
        <link
          rel="canonical"
          href="https://surosowancyber.or.id/registration"
        />
      </Helmet>
      <Header></Header>
      <Registration></Registration>
      <Footer></Footer>
    </>
  );
};

export default RegistrationPage;

import bg from "../../../assets/images/background/404.png";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <>
      <section className="page__title error__page p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${bg})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            <h1
              className="title"
              data-aos="zoom-in"
              data-aos-delay="800"
              data-aos-duration="3000"
            >
              4<span>0</span>4
            </h1>
            <h2>Oops..!!! Halaman Tidak Ditemukan!</h2>
            <h5>Hayolo... Kamu Mau Kemana??</h5>
            <div className="btn-box">
              <Link to="/" className="theme-btn theme-btn-one">
                <i className="icon-02"></i> Balik Lagi
              </Link>
            </div>
          </div>
        </div>
      </section>
      ;
    </>
  );
};

export default Error;

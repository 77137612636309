import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import shap1 from "../../../../assets/images/banner/banner-shap-01.png";
import BannerBg from "../../../../assets/images/banner/banner-bg.png";
import Banner1 from "../../../../assets/images/activities/home.jpeg";
import BannerLogo from "../../../../assets/images/logo/banner-logo.png";
import { Parallax } from "react-parallax";

const Banner = () => {
  useEffect(() => {
    const text_2 = document.querySelector(".text_2");
    if (text_2) {
      text_2.innerHTML = text_2.innerText
        .split("")
        .map(
          (char, i) =>
            `<span style="transform:rotate(${i * 11.0}deg)">${char}</span>`
        )
        .join("");
    }
  }, []);
  return (
    <section className="banner__one ">
      <div className="pattern-layer">
        <Parallax
          offset={0}
          speed={2.8}
          bgImage={shap1}
          strength={100}
          className="banner__icon pattern-1 p_absolute"
        ></Parallax>
      </div>
      <div className="banner__data p_relative">
        <div
          className="banner__bg"
          style={{ backgroundImage: `url(${BannerBg})` }}
        ></div>
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div
                className="banner__left"
                data-aos="fade-right"
                data-aos-delay="250"
                data-aos-duration="1000"
              >
                <div className="sub__title">
                  <h4>Selamat Datang di</h4>
                </div>
                <div className="banner__title">
                  <h1>
                    Komunitas Surosowan <span>Cyber</span>{" "}
                  </h1>
                </div>
                <div className="text">
                  <p>
                    Tempat untuk berbagi dan menambah pengetahuan tentang
                    Teknologi Informasi di Banten.
                  </p>
                </div>
                <div className="btn-box">
                  <Link to="/contact" className="theme-btn theme-btn-one">
                    <i className="icon-02"></i> Hubungi Kami
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div
              className="col-lg-4 col-md-12"
              data-aos="zoom-in-up"
              data-aos-delay="250"
              data-aos-duration="1000"
            >
              <div className="banner__right p_relative">
                <div className="image__one">
                  <figure
                    className="image-box wow slideInUp animated"
                    data-wow-delay="00ms"
                    data-wow-duration="1500ms"
                    style={{ visibility: "visible" }}
                  >
                    <img
                      src={Banner1}
                      alt="Staff
                    Surosowan Cyber"
                    />
                  </figure>
                </div>
                <div className="icon__image__banner">
                  <div className="circle-main">
                    <div className="circle">
                      <img
                        src={BannerLogo}
                        style={{ width: "120px" }}
                        alt="Surosowan Cyber"
                      />
                      <div className="round-text">
                        <div className="text_1">
                          <p className="text_2">
                            Surosowan Siber Surosowan Siber
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;

import React from "react";
import Header from "../components/layouts/Header/Header";
import Footer from "../components/layouts/Footer/Footer";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>Kontak - Surosowan Siber</title>
        <meta
          name="keywords"
          content="Information Technology Foundation, Community, Network, Web Development, Full-Stack Developer"
        />
        <meta name="author" content="Surosowan Siber Foundation" />
        <meta image="https://surosowancyber.or.id/images/logo/header-logo.png" />

        <meta
          property="og:url"
          content="https://surosowancyber.or.id/contact"
        />
        <meta property="og:type" content="foundation" />
        <meta property="og:title" content="Surosowan Siber Foundation" />
        <meta
          property="og:description"
          content="Jika anda ingin berdiskusi dan atau mempunyai sebuah pertanyaan terkait Teknologi Informasi.
          kami siap memberikan jawaban"
        />
        <meta property="og:site_name" content="Surosowan Siber Foundation" />
        <meta
          property="og:image"
          content="https://surosowancyber.or.id/static/media/banner-logo.ba4985e73aff29852c51.png"
        />
        <link rel="canonical" href="https://surosowancyber.or.id/contact" />
      </Helmet>
      <Header></Header>
      <Footer></Footer>
    </>
  );
};

export default ContactPage;
